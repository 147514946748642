<template>
  <div>
    <b-row :cols="12" class="mb-4">
      <b-col :cols="12" v-for="(value, index) in values" :key="index">
        <slot :value="value" :index="index"></slot>
      </b-col>
    </b-row>
    <slot name="footer">
      <div @click="add" class="button btn-success">
        <i class="menu-icon material-icons-outlined">add</i>
      </div>
    </slot>
  </div>
</template>

<style lang="stylus" scoped>

@import '../../style/colors.styl';
@import '../../style/fonts.styl';

.button
    display inline-flex
    cursor pointer
    padding 10px
    border-radius 50%
    text-align center
    background-color white
    color gray
    border solid 1px gray
    transition .25s
    min-width 40px
    min-height 40px
    margin-bottom -24px

    i
        font-size 20px

    &:hover
      color #00DDEE
      border solid 1px #00DDEE

.btn-success
  &:hover
    color #28A745
    border solid 1px #28A745

.btn-danger
  &:hover
    color #DB2323
    border solid 1px #DB2323
</style>
<script>
export default {
  props: {
    values: {
      type: Array,
      required: true
    }
  },
  methods: {
    add () {
      this.$emit('add')
    }
  }
}
</script>
