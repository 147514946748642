<template>
  <Content  :route="'/'" :action="action" title="Configurar Sistema" @event="updateValue()">
    <div class="d-flex">
      <ConfigureForm ref="form" style="background-color: white;border-radius: 16px; padding: 16px 24px;border: 1px solid rgba(0,0,0,.125);"></ConfigureForm>
    </div>
      </Content>
  </template>

<script>
import Content from '@/components/content'
import { request } from '@/services'
import { toast } from '@/utils'
import ConfigureForm from '../components/forms/ConfigureForm.vue'
export default {
  components: {
    Content,
    ConfigureForm
  },
  data () {
    return {
      isLoading: true,
      identifyObject: null,
      userSelected: null
    }
  },
  computed: {
    action () {
      return { options: { label: 'Salvar', icon: 'save' } }
    },
    obj () {
      return this.$store.getters.getGroupObj
    },
    routeKey () {
      const key = this.$store.getters.getGroupObj
      return key.key
    },
    route () {
      return this.$route.path
    }
  },
  created () {
    this.$store.dispatch('usuarios/init', { self: this })
  },
  methods: {
    updateValue () {
      const objKeys = this.$store.getters.values
      const objValues = this.$store.getters.objValues
      const groupKey = this.$store.getters.getRanges
      const obj = {
        key: objKeys.map(key => key.key)[0],
        type: objKeys.map(key => key.type)[0],
        description: objKeys.map(key => key.description)[0],
        group_key: JSON.parse(groupKey),
        object_uri: objValues.map(key => key.object_uri)[0],
        object_name: objValues.map(key => key.object_name)[0],
        object_module: [0, objValues.map(key => key.object_module)[0]],
        user_id: this.userSelected !== null ? this.userSelected : this.$store.state.user.id
      }
      console.log(obj)
      for (const e of [...this.$refs.form.ranges, ...this.$refs.form.ranges2]) {
        request.post('extra_field/create', e,
          response => {
            // toast(this, 'success', 'Rota', `Rota ${this.routeKey} cadastrada com sucesso`)
            console.log(response.data)
          },
          error => {
            toast(this, 'danger', 'Campo da rota não atualizado', error)
            return Promise.reject(error)
          }
        )
      }
    }
  }
}
</script>

  <style>
  .label-code{
      width: 15vw;
      font-weight: 600;
      padding: 2%;
  }
  .code-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      justify-content: space-between;
      align-items: flex-start;
      width: 95%;
      padding: 1%;
  }

  .btn-padrao{
    width: 5vw;
  }

  .code-action {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 55px;
  }
  /*
  .code {
      display: flex;
      width: 75vw;
      background-color: #262626;
      height: 15vh;
      outline: none;
      padding: 1%;
  } */
  textarea {
    display: flex;
    color: #4ADB26;
    width: 75vw;
    background-color: #262626;
    height: 15vh;
    outline: none;
  }
  </style>
